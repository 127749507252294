import { ApiRouting } from "../routes/ApiRouting";

export default class UserApi {
    static getUser(index, token, callBack) {
        
        fetch(ApiRouting.GET_CANDIDAT_BY_USER_ID(index), {
            headers: { 'Content-Type': 'application/json', 'X-JWT-Token' : token },
            method: 'GET'
        })
        .catch(err => {
            console.log(err)
        })
        .then(res => res.json())
        .then((response) => {
            console.log("GET USER RES",response)
            if (response.candidat) {
                callBack(response.candidat)
            }

            if (response.message) {
                callBack(response)
            }
        });
    }

    static async log(email, password, userTypeId, callback) {
        fetch(ApiRouting.LOGIN, {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({
                "userType" : userTypeId,
                "email": email,
                "password": password
            })
        }).then(res => {
            res.json().then(result => {
                callback(result,null)
            })
        }).catch(e =>{
            callback(null,e)
        })
    }
}