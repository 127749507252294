import React from 'react';
import { Form, Button, Modal } from 'react-bootstrap';

export default function CandidatSignUpComponent(props) {
    return (
        <Modal
            id="component-CandidatSignUp"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Création d'un nouveau compte pour candidat
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div id="form-CreateCandidat" className={props.className} style={props.style}>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Nom</Form.Label>
                            <Form.Control type="text" placeholder="Saisir votre nom" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Prénom</Form.Label>
                            <Form.Control type="text" placeholder="Saisir votre prénom" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Date de naissance</Form.Label>
                            <Form.Control type="text" placeholder="Saisir votre date de naissance" />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label className="fieldLabel">Email</Form.Label>
                            <Form.Control className="field" type="email" placeholder="Saisir votre email" required />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Mot de passe</Form.Label>
                            <Form.Control type="password" placeholder="Saisir un mot de passe" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Confirmer votre mot de passe</Form.Label>
                            <Form.Control type="password" placeholder="Confirmer votre mot de passe" />
                        </Form.Group>
                     
                        <Button variant="primary" type="submit">
                            Créer un compte
                        </Button>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    )
}
