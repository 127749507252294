import './LoginView.scss';
import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { info, instagram, linkedin, snapchat, youtube } from '../../assets/icons/icons';
import FormLogComponent from '../../components/formLoginComponent/FormLogComponent';
import EmployeurSignUpComponent from '../../components/formSignUpComponent/EmployeurSignUpComponent';
import CandidatSignUpComponent from '../../components/formSignUpComponent/CandidatSignUpComponent';
import { Navigate, useNavigate, useParams } from 'react-router';
import { checkAndGetUserTypeByUrlParams } from '../../utils/UrlUtils';
import { UserType } from '../../Enums/UserType';
import { isBrowser, isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { AppRouting } from '../../routes/AppRouting';
import { Info } from '@mui/icons-material';
import Networks from '../../components/networksComponent/networksComponent';

function LoginView() {
    const params = useParams()
    const userType = checkAndGetUserTypeByUrlParams(params)

    const candidatWelcomeMessage = "Vous êtes à la recherche d'un emploi en tant que secrétaire médical(e) ou assistant(e) médico-administratif(ve) jamais inscrit(e) ?"
    const employeurWelcomeMessage = "Vous êtes un(e) professionnel(le) à la recherche d'un(e) secrétaire médical(e) ou assistant(e) médico-administratif(ve) jamais inscrit(e) ? "

    const form = userType === UserType.CANDIDAT ? CandidatSignUpComponent : userType === UserType.EMPLOYEUR ? EmployeurSignUpComponent : null
    
    const [modalShow, setModalShow] = useState(false);

    return userType != null ? ( 
        <Row id="view-Login" className="p-0 m-0">
            {/* -----------------COL REGISTER----------------- */}
            {(isBrowser) ?
                <Col id="col-register" className="d-flex flex-column justify-content-center align-items-center p-0 m-0">

                    <div className="sign-up d-flex flex-column justify-content-center align-items-center ">
                        <div className="text-white text-center px-5 mx-5 mb-4">
                            <h1>Bienvenue</h1>
                            <p>{userType === UserType.CANDIDAT ? candidatWelcomeMessage : userType === UserType.EMPLOYEUR ? employeurWelcomeMessage : ""}</p>
                        </div>
                        <div id="btnInscription" onClick={() => setModalShow(true)}>
                            S'inscrire
                        </div>
                    </div>
                </Col> : null}

            {/* -----------------COL LOGIN----------------- */}

            <Col id="col-login" className="d-flex flex-column justify-content-around align-items-around  p-0 m-0">
                <div className="text-center mt-5">
                    <img id="logo" src="/assets/images/meedic.png" alt="logo" />
                    {isBrowser ? <Networks /> : null}
                </div>

                <div className="text-center">
                    <p>Se connecter en tant que <b>{userType === UserType.CANDIDAT ? "candidat" : userType === UserType.EMPLOYEUR ? "employeur" : ""}</b></p>
                </div>

                <div id="loginForm">
                    <FormLogComponent userType={userType} />
                    {isMobile ? <button className="btnInscriptionDisplayMobile" onClick={() => setModalShow(true)}>
                        S'inscrire
                    </button> : null}
                </div>



                {
                    isBrowser ?
                        <div>
                            {isBrowser ? <div className="aboutContent">
                                <Link to={AppRouting.ABOUT}>A propos de MEEDIC</Link>
                                <Link to={AppRouting.LEGAL_MENTIONS}>Mentions Légales</Link>
                            </div> : null}

                            <div id="cookies">
                                <p>
                                    <b>PRÉFÉRENCES EN MATIÈRE DE COOKIES</b><br />
                                    Concernant l’utilisation des cookies, vous pouvez à tout moment modifier vos préférences, et notamment retirer votre consentement, en cliquant <a href="cookie"> ici</a>.
                                </p>

                                <p>
                                    <b>DONNÉES PERSONNELLES</b><br />
                                    Pour plus d’informations sur le traitement de vos données personnelles, nous vous invitons à vous référer à notre charte de protection <a href="rgpd">des données personnelles</a>.
                                </p>
                            </div>
                        </div> : <Networks />
                }
            </Col>
            {form({
                className: "p-2",
                show: modalShow,
                onHide: () => setModalShow(false)
            })}
        </Row>

    ) : <Navigate to={AppRouting.TOGGLE_LOGIN}></Navigate>
}

export default LoginView
