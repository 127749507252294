import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Card, Button, Container } from 'react-bootstrap';
import { pencil, star } from '../../assets/icons/icons';
import { UserContext } from '../../contexts/UserContext';
import './SideBarComponent.scss'
import { AppRouting } from '../../routes/AppRouting';
import { SessionManagement } from '../../sessionManagement';

function SideBarComponent() {

    const navigate = useNavigate();
    const userContext = useContext(UserContext);
    const data = userContext.userData;

    const deconnexion = () => {
        console.log("Déconnexion")
        SessionManagement.removeSession(userContext)
        navigate(AppRouting.ROOT)
    }

    return !data ? (<p>Loading...</p>) :
        (
            <Container id="component-sidebar" className="h-100 d-flex flex-column align-items-center">
                {console.log("data", data)}
                <Card className="mt-3 d-flex flex-column align-items-center bg-transparent border-0 text-center" >

                    <Card.Img id="sidebarIMG" variant="top" className="rounded-circle" src={data.lien_photo_profil ? data.lien_photo_profil : "/assets/images/empty.png"} />
                    <Card.Body>
                        <Card.Title>{data.prenom} {data.nom}</Card.Title>
                        <Card.Text>
                            {data.description}
                        </Card.Text>

                        <Link className='noDecorationLink' to="">
                            <Button className="d-flex align-items-center mr-1" size="sm" variant="outline-secondary">
                                <span className="d-flex align-items-center mr-2">{pencil}</span>
                                Modifier le profil
                            </Button>
                        </Link>
                    </Card.Body>
                </Card>
                <p>{star} Mes favoris</p>
                <Link className='noDecorationLink' to={AppRouting.ABOUT}>
                    <Button className="d-flex align-items-center mr-1" size="sm" variant="outline-secondary">
                        A propos de Meedic
                    </Button>
                </Link>
                <Button onClick={deconnexion}>
                    Déconnexion
                </Button>
            </Container>
        )
}

export default SideBarComponent



