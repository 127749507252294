import React, {useState,useCallback, useContext} from 'react';
import { Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { ApiRouting } from '../../routes/ApiRouting';
import { SessionManagement } from '../../sessionManagement';
import { UserContext } from '../../contexts/UserContext';
import { AppRouting } from '../../routes/AppRouting';
import UserApi from '../../api/UserApi';
import context from 'react-bootstrap/esm/AccordionContext';


export default function FormLogComponent(props) {
    const userContext = useContext(UserContext);
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [email, setEmail] = useState("j.candidat@avlis.fr");
    const [password, setPassword] = useState("testpassword");

    const userType = props.userType;   
    
    const handleLog = (res, err) => {
        if(res){
            if(res.error){
                console.log(res.error)
            }else{
                SessionManagement.createSession(userContext,res)
                navigate(AppRouting.SET_HOME_WITH_PARAM(userType.urlValue))
            }
        }else{
            console.log(err)
        }
    }

    const log = useCallback(
        async (e) => {
            e.preventDefault()
            UserApi.log(email,password,userType.id, handleLog)
        },
        [navigate, email, password, userContext],
    )

    
    const handleSubmit = (e) => {
        const form = e.currentTarget;
        setValidated(true);
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            log(e)
        }
    };
    
    return (
        <div id="component-FormLog">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label className="fieldLabel">Email</Form.Label>
                    <Form.Control className="field" type="email" placeholder="Saisir l'email"  value={email} onChange={(e) => { setEmail(e.target.value) }} required />
                </Form.Group>

                <Form.Group className="mt-3" controlId="formBasicPassword">
                    <Form.Label className="fieldLabel">Mot de passe</Form.Label>
                    <Form.Control className="field" type="password" placeholder="Saisir le mot de passe" value={password} onChange={(e) => { setPassword(e.target.value) }} required />
                </Form.Group>

                <Link to="/#" id="mdpLink">Mot de passe oublié ?</Link>

                {['checkbox'].map((type) => (
                    <div key={`default-${type}`} className="mt-3">
                        <Form.Check
                            type={type}
                            id={`default-${type}`}
                            label='Rester connecté'
                        />
                    </div>
                ))}

                <div className="d-flex justify-content-center">
                    <button className="btnConnexion mt-3"  type="submit">Se connecter à votre espace</button>
                </div>
            </Form>
        </div>
    )
}
