import React from "react";
import './LegalMentions.scss';

export default function LegalMention() {
    return (
        <div id="view-legalMentions" className="d-flex flex-column align-items-center">
            <img className="img" src="/assets/images/meedic.png" />
            <h1>Mentions Légales</h1>
            <p>
                [A COMPLETER]
            </p>
        </div>
    )
}