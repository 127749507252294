import { UrlValue } from "../Enums/UrlValues";
import { UserType } from "../Enums/UserType";

const checkAndGetUserTypeByUrlParams = (params) => {
    var userType = null;
    if (params.userType) {
        if (params.userType === UrlValue.CANDIDAT) {
            userType = UserType.CANDIDAT;
        } else if (params.userType === UrlValue.EMPLOYEUR) {
            userType = UserType.EMPLOYEUR;
        }
    }
    return userType;
}

export { checkAndGetUserTypeByUrlParams }