import { UrlValue } from "./UrlValues";

export const UserType = {
    DEFAULT : {id : 0, urlValue : ""},
    ADMIN : {id : 1, urlValue : UrlValue.ADMIN},
    CANDIDAT : {id : 2, urlValue : UrlValue.CANDIDAT},
    EMPLOYEUR : {id : 3, urlValue : UrlValue.EMPLOYEUR},

    compare : function(obj, userType){
        const objLength = Object.keys(obj).length;
        const userTypeLength = Object.keys(userType).length;
    
        if (objLength === userTypeLength) {
            return Object.keys(obj).every(
                key => userType.hasOwnProperty(key)
                    && userType[key] === obj[key]);
        }
        return false;
    },
    checkUserType : function(id, userType){
       return userType.id === id;
    },
    getUserType : function(id){
        var userType = null;
        const keys = Object.keys(this);
        for (let index = 0; index < keys.length; index++) {
            const key = keys[index];
            const value = this[key];
            if(value.id){
                if(value.id === id){
                    userType = value;
                }
            }
        }
        return userType;
    }
}