import UserApi from "./api/UserApi";
import { UserType } from "./Enums/UserType";

export const SessionManagement = {
    getSessionStorageOrDefault : (key,defaultValue)=>{
        const stored = sessionStorage.getItem(key);
        if(!stored){
            return defaultValue;
        }
        return JSON.parse(stored);
    },
    setSessionItem : (key,value)=>{
        sessionStorage.setItem(key,JSON.stringify(value));
    },
    createSession : (context, value) => {
        context.setUserId(value.userId);
        context.setUserType(UserType.getUserType(value.typeUser))
        context.setUserToken(value.token);
        context.setIsLogged(true);

        UserApi.getUser(value.userId, value.token, context.setUserData);
        SessionManagement.setSessionItem("user", {id: value.userId, token: value.token, userTypeId : value.typeUser});
    },
    removeSession : (context) => {
        context.setUserId(0)
        context.setUserToken("")
        context.setUserType(UserType.DEFAULT)
        context.setUserData(null)
        context.setIsLogged(false)
        sessionStorage.removeItem("user")
    }
}