import React, { useState, useEffect, useContext } from "react";
import { Card } from "react-bootstrap";
import { isBrowser, isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import UserApi from "../../api/UserApi";
import { briefcase_outline, cv, statistics } from "../../assets/icons/icons";
import { UserContext } from "../../contexts/UserContext";
import { UserType } from "../../Enums/UserType";
import { AppRouting } from "../../routes/AppRouting";
import "./HomeViewStyle.scss";

function HomeView() {
  const context = useContext(UserContext);
  const data = context.userData;

  return !data ? (<p>Loading...</p>) :
    (
      <div id="view-home">
        {isBrowser ? <DashBoardWeb data={data} userType={context.userType}></DashBoardWeb> : isMobile ? <DashBoardMobile context={context}></DashBoardMobile> : null}
      </div>
    );
}

function DashBoardItem(props) {
  return (
    <Link className={isMobile ? "dashBoardItem dbiMobile" : isBrowser ? "dashBoardItem" : ""} to={props.url}>
      <Card className={isMobile ? "dashBoardCard dbcMobile" : isBrowser ? "dashBoardCard" : ""}>
        {props.icon}
        <span>{props.title}</span>
      </Card>
    </Link>
  );
}

function DashBoardMobile(props) {
  const context = props.context;

  const candidatDashBoard = (
    <>
      <DashBoardItem icon={briefcase_outline} title="Recherche d'emplois" url=""></DashBoardItem>
      <DashBoardItem icon={cv} title="Gérer son CV" url=""></DashBoardItem>
    </>
  );

  const employeurDashBoard = (
    <>
      <DashBoardItem icon={cv} title="Recherche de profils" url=""></DashBoardItem>
    </>
  );

  return (
    <div id="dashBoardContent" className={isMobile ? "mobileTopMargin" : ""}>
      {UserType.compare(context.userType, UserType.EMPLOYEUR) ? employeurDashBoard : UserType.compare(context.userType, UserType.CANDIDAT) ? candidatDashBoard : null}
    </div>
  )
}

function DashBoardWeb(props) {
  const data = props.data;
  return (
    <>
      <p>Bonjour {data.prenom}</p>
      <Link to={AppRouting.SET_RECHERCHE_WITH_PARAM(props.userType.urlValue)}>Accéder à la recherche</Link>
    </>
  )
}
export default HomeView;
