import React from 'react';
import ListeCandidatComponent from '../../components/annonceComponent/ListeCandidatComponent';
import OffreEmploiComponent from '../../components/annonceComponent/OffreEmploiComponent';

export default function AnnoncesView() {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center">
            <OffreEmploiComponent />
            <ListeCandidatComponent />
        </div>
    )
}
