import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import './NavBarComponent.scss'


export default function NavBar() {
  const [value, setValue] = React.useState(0);

  return (
      <Box id="component-navbar">
        <BottomNavigation
          className="navContent"
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction icon={<SettingsIcon fontSize="large" />} />
          <BottomNavigationAction icon={<HomeIcon fontSize="large" />} />
          <BottomNavigationAction icon={<PersonIcon fontSize="large"/>} />
        </BottomNavigation>
      </Box>
  );
}