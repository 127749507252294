export class ApiRouting {
    //URL -> /auth/
    static AUTH_ROOT = "/auth/"
    //URL -> /auth/login
    static LOGIN = ApiRouting.AUTH_ROOT + "login";

    // --- TOUTES LES REQUETES DANS API NECESSITENT UN TOKEN JWT --- // 

    //URL -> /api/ 
    static API_ROOT = "/api/";

    //URL -> /api/candidat/
    static CANDIDAT_ROOT = ApiRouting.API_ROOT + "candidat/"

    //URL -> /api/find-one-by-user/:userid
    static GET_CANDIDAT_BY_USER_ID(userId) {
        return this.CANDIDAT_ROOT + "find-one-by-user/" + userId;
    };
}