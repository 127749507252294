export class AppRouting {
    //URL -> /
    static ROOT = "/"
    static USER_APP_ROOT = ":userType";

    //URL -> /About
    static ABOUT = this.ROOT+"about";

    //URL -> /Mentions-Legales
    static LEGAL_MENTIONS = this.ROOT+"mentions-legales";
    //URL -> /login
    static TOGGLE_LOGIN = this.ROOT+"login/";

    //URL -> /login/:userType
    static LOGIN = this.TOGGLE_LOGIN+this.USER_APP_ROOT
    static SET_LOGIN_WITH_PARAM(userType){
        return this.LOGIN.replace(this.USER_APP_ROOT,userType);
    }

    //URL -> /:userType/home
    static HOME = this.ROOT+this.USER_APP_ROOT+"/accueil"
    static SET_HOME_WITH_PARAM(userType){
        return this.HOME.replace(this.USER_APP_ROOT,userType);
    }
    
    //URL -> /:userType/recherche
    static RECHERCHE = this.ROOT+this.USER_APP_ROOT+"/recherche"
    static SET_RECHERCHE_WITH_PARAM(userType){
        return this.RECHERCHE.replace(this.USER_APP_ROOT,userType);
    }
}