import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { isBrowser } from 'react-device-detect';
import { Link } from 'react-router-dom';
import Networks from '../../components/networksComponent/networksComponent';
import { UserType } from '../../Enums/UserType';
import { AppRouting } from '../../routes/AppRouting';

import './ToggleLog.scss';


function ToggleLog() {
    return (
        <Row id="view-ToggleLog" className="p-0 m-0">

            {/* -----------------COL ILLUSTRATION----------------- */}
            <Col id="col-illustration" className="p-0 m-0">
            </Col>

            {/* -----------------COL CONTENT----------------- */}
            <Col id="col-content" className="d-flex flex-column justify-content-around align-items-around text-center p-0 m-0">
                <div className="text-center mt-5">
                    <img id="logo" src="/assets/images/meedic.png" alt="logo" />
                    {isBrowser ? <Networks /> : null}
                </div>

                <div>

                    <h5 id="toggleLoginTitle" >Se connecter en tant que</h5>

                    <div id="toggleLog-wrapper">
                        <Link className="toggle-button" to={AppRouting.SET_LOGIN_WITH_PARAM(UserType.EMPLOYEUR.urlValue)}><span >Employeur</span></Link>

                        <Link className="toggle-button" to={AppRouting.SET_LOGIN_WITH_PARAM(UserType.CANDIDAT.urlValue)}> <span>Candidat</span></Link>
                    </div>
                </div>


                {
                    isBrowser ?
                        <div>
                            {isBrowser ? <div className="aboutContent">
                                <Link to={AppRouting.ABOUT}>A propos de MEEDIC</Link>
                                <Link to={AppRouting.LEGAL_MENTIONS}>Mentions Légales</Link>
                            </div> : null}

                            <div id="cookies">
                                <p>
                                    <b>PRÉFÉRENCES EN MATIÈRE DE COOKIES</b><br />
                                    Concernant l’utilisation des cookies, vous pouvez à tout moment modifier vos préférences, et notamment retirer votre consentement, en cliquant <a href="cookie"> ici</a>.
                                </p>

                                <p>
                                    <b>DONNÉES PERSONNELLES</b><br />
                                    Pour plus d’informations sur le traitement de vos données personnelles, nous vous invitons à vous référer à notre charte de protection <a href="rgpd">des données personnelles</a>.
                                </p>
                            </div>
                        </div> : <Networks />
                }

            </Col>
        </Row>
    )
}

export default ToggleLog
