import React from 'react';
import { Form, Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { tooltip } from '../../assets/icons/icons';

export default function EmployeurSignUpComponent(props) {
    return (
        <Modal
            id="component-EmployeurSignUp"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Création d'un nouveau compte Employeur
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div id="form-CreateCandidat" className={props.className} style={props.style}>
                    <Form>
                        <h5><b>Informations de l'établissement</b></h5>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Nom de l'établissement</Form.Label>
                            <Form.Control type="text" placeholder="Saisir le nom de votre établissement" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Numéro de SIRET</Form.Label>
                            <Form.Control type="text" placeholder="Saisir votre numéro de SIRET" />
                        </Form.Group>
                        <h5><b>Contact du responsable recrutement</b></h5>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Nom</Form.Label>
                            <Form.Control type="text" placeholder="Saisir votre nom" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Prénom</Form.Label>
                            <Form.Control type="text" placeholder="Saisir votre prénom" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Téléphone</Form.Label>
                            <Form.Control type="text" placeholder="Saisir votre numéro de téléphone" />
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail"> 
                            <Form.Label className="fieldLabel">Email</Form.Label> <OverlayTrigger
                                key='top'
                                placement='top'
                                overlay={
                                    <Tooltip id={`tooltip-top`}>
                                        Cette adresse servira aussi d'adresse d'authentification
                                    </Tooltip>
                                }
                            >
                                {tooltip}
                            </OverlayTrigger>
                            <Form.Control className="field" type="email" placeholder="Saisir votre email" required />
                        </Form.Group>
                        <h5><b>Informations de connexion</b></h5>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Mot de passe</Form.Label>
                            <Form.Control type="password" placeholder="Saisir un mot de passe" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Confirmer votre mot de passe</Form.Label>
                            <Form.Control type="password" placeholder="Confirmer votre mot de passe" />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Créer un compte
                        </Button>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    )
}
