import React from "react";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { info, instagram, linkedin, snapchat, youtube } from "../../assets/icons/icons";
import { AppRouting } from "../../routes/AppRouting";
import "./networksComponent.scss"

export default function Networks() {
    return (
        <div id="component-networks">
            <span>{linkedin}</span>
            <span>{youtube}</span>
            <span>{instagram}</span>
            <span>{snapchat}</span>
            {isMobile ? <Link to={AppRouting.ABOUT}><span>{info}</span></Link> : null}
        </div>
    )
}