import React from "react"
import { Form, Button } from "react-bootstrap"
import { Link } from 'react-router-dom';
import { linkedin, youtube, instagram, snapchat } from "../../assets/icons/icons"
import { AppRouting } from "../../routes/AppRouting"
import "./aboutView.scss"
export default function AboutView() {
    return (
        <div id="view-about" className="d-flex flex-column align-items-center">
            <img className="img" src="/assets/images/meedic.png" />
            <h1>A propos de Meedic Recrutement</h1>
            <p>
                Meedic est un site, ainsi qu'une application mobile d'accompagnement des médecins et assistant(e)s médical dans leurs recherches d'employés et d'emplois.
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga minima, illum alias debitis optio quo! Vel illo recusandae nobis reprehenderit molestiae iste, esse enim? Ipsum necessitatibus doloribus laboriosam similique labore.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos temporibus asperiores praesentium quibusdam quos? Deleniti sunt vel in, quod ea fuga, quidem fugit facere dolor incidunt, dolores expedita voluptas veniam.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias expedita nobis labore dolore, minus quos saepe eveniet? Cumque dolore ut neque! Iusto a at enim perspiciatis sequi necessitatibus aliquam voluptatibus?
            </p>
            <hr />
            <h2>Nous contacter</h2>
            <div id="contacts">
                <div className="contactPart">
                    <p>Vous pouvez retrouver Meedic Recrutement et Avlis Formation sur les réseaux sociaux</p>
                    <div id="networks">
                        <a className="networkItem" href="" target="_blank"><span>{linkedin}</span></a>
                        <a className="networkItem" href="" target="_blank"><span>{youtube}</span></a>
                        <a className="networkItem" href="" target="_blank"><span>{instagram}</span></a>
                        <a className="networkItem" href="" target="_blank"><span>{snapchat}</span></a>
                    </div>
                </div>
                <div className="contactSeparator"></div>
                <div className="contactPart">
                    <p>Vous pouvez aussi nous contacter par mail à l'adresse [contact Meedic] où via le formulaire ci-dessous</p>
                    <Form className="contactForm">
                        <Form.Group className="mb-3 fieldGroup" controlId="exampleForm.ControlInput1">
                            <Form.Label>Votre adresse mail</Form.Label>
                            <Form.Control type="email" placeholder="name@example.com" />
                        </Form.Group>
                        <Form.Group className="mb-3 fieldGroup" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Votre message</Form.Label>
                            <Form.Control as="textarea" rows={3} />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Envoyer
                        </Button>
                    </Form>
                </div>
            </div>
            <hr />
            <p>Propriétée d'<a href="https://avlis.fr/" target="_blank">Avlis Formation</a></p>
            <Link to={AppRouting.LEGAL_MENTIONS}><p>Mentions Légales</p></Link>
            <p>Copyright © 2020 Meedic Recrutement. All rights reserved.</p>
        </div>
    )
}