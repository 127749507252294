import React from 'react'
import { UserType } from '../Enums/UserType';

const defaultValue = {
    userId : 0,
    userType : UserType.DEFAULT,
    userToken : "",
    isLogged : false,
    userData : null,
    setUserId : () => {},
    setUserType : () => {},
    setUserToken : () => {},
    setIsLogged : () => {},
    setUserData : () => {}
}

export const UserContext = React.createContext(defaultValue);