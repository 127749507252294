import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route, Navigate } from 'react-router';
import { isBrowser, isMobile } from 'react-device-detect';
import { Row, Col } from 'react-bootstrap';
import TopBar from './components/topBarComponent/TopBarComponent'
import NavBar from './components/navBarComponent/NavBarComponent'
import { UserContext } from './contexts/UserContext'
import { SessionManagement } from './sessionManagement';
import ToggleLog from './views/login/ToggleLog';
import LoginView from './views/login/LoginView';
import './App.scss'
import SideBarComponent from './components/sideBarComponent/SideBarComponent';
import { AppRouting } from './routes/AppRouting';
import AnnoncesView from './views/annonces/AnnoncesView';
import HomeView from './views/home/HomeView';
import AboutView from './views/about/aboutView';
import LegalMention from './views/legalMentions/LegalMentions';
import { UserType } from './Enums/UserType';
import UserApi from './api/UserApi';
import NotFound from './views/notFound/notFoundView';

function App() {
  var [userId, setUserId] = useState(0);
  var [userToken, setUserToken] = useState("");
  var [userType, setUserType] = useState({});
  var [isLogged, setIsLogged] = useState(false);
  var [userData, setUserData] = useState({});

  var topBarDisplay = (userId !== 0 && userToken !== "") ? "" : "d-none";
  var sideBarDisplay = (userId !== 0 && userToken !== "") && isBrowser ? "" : "d-none";

  console.log("APP", userId, userToken)
  useEffect(() => {
    if (!isLogged) {
      const session = SessionManagement.getSessionStorageOrDefault("user", null);
      console.log("session", session)
      if (session != null) {
        setUserId(session.id)
        setUserToken(session.token)
        setIsLogged(true)
        if (session.userTypeId) {
          setUserType(UserType.getUserType(session.userTypeId))
        }
        UserApi.getUser(session.id, session.token, setUserData);
      }
    }
  }, [userId, userToken, isLogged]);
  return (
    <UserContext.Provider value={{ userId, setUserId, userType, setUserType, userToken, setUserToken, isLogged, setIsLogged, userData, setUserData }}>
      <BrowserRouter>
        <div id="app">
          {/* ------------TOPBAR DISPLAY------------- */}
          <header className={`${topBarDisplay}`}>
            <TopBar />
          </header>

          <Row className="gx-0 h-100">
            <Col id="app-inside" className={!isLogged ? "noMargin" : ""}>
              <Row className={isMobile ? "gx-0 h-100 mobile-inside "+ (!isLogged ? "noMargin" : "") : isBrowser ? "gx-0 h-100" : ""}>
                {/* SIDE BAR  */}
                <Col lg={2} md={2} xs={2} className={"p-0 " + sideBarDisplay}>
                  <SideBarComponent />
                </Col>

                <Col id="app-content" className={!isLogged ? "noMargin" : ""}>
                  <Routes>
                    <Route path={AppRouting.ROOT} element={<Navigate to={AppRouting.TOGGLE_LOGIN}></Navigate>} />
                    <Route path={AppRouting.ABOUT} element={<AboutView />} />
                    <Route path={AppRouting.LEGAL_MENTIONS} element={<LegalMention />} />
                    <Route path={AppRouting.LOGIN} element={isLogged ? <Navigate to={AppRouting.SET_HOME_WITH_PARAM(userType.urlValue)} /> : <LoginView />} />
                    <Route path={AppRouting.TOGGLE_LOGIN} element={isLogged ? <Navigate to={AppRouting.SET_HOME_WITH_PARAM(userType.urlValue)} /> : <ToggleLog />} />
                    <Route path={AppRouting.HOME} element={!isLogged ? <Navigate to={AppRouting.TOGGLE_LOGIN} /> : <HomeView />} />
                    <Route path={AppRouting.RECHERCHE} element={!isLogged ? <Navigate to={AppRouting.TOGGLE_LOGIN} /> : <AnnoncesView />} />
                    <Route path='*' element={<NotFound />} />
                  </Routes>
                </Col>
              </Row>
            </Col>
          </Row>
          {isMobile && isLogged ? <NavBar /> : null}
        </div>
      </BrowserRouter>
    </UserContext.Provider>
  )
}

export default App
