import React from 'react';
import './OffreEmploiComponent.scss';
import {download} from '../../assets/icons/icons';

export default function OffreEmploiComponent() {
    return (
        <div id="component-offreEmploi" className="row">
            <div className="col-8 p-0">
                <ul>
                    <li><h3>Assistant médico-administrative</h3></li>
                    <li><h4>Biopath</h4></li>
                    <li><p>Bondy, 93</p></li>
                    <li><b>Spécialité</b> : Laboratoire</li>
                    <li><b>Type de contrat</b> : CDD, possibilité de CDI</li>
                    <li><b>Salaire</b> : 1500 et 1700 € net/mois</li>
                    <li><b>Télétravail</b> : Non</li>
                    <li><b>Date de prise de poste</b> : Dès que possible</li>
                </ul>
            </div>


            <div className="col-3 p-0 d-flex flex-column justify-content-center align-items-center">
                <div>
                    <img src="/assets/images/empty.png" alt="photo" />
                </div>
                <div className="text-center">
                    <p>Télécharger la fiche de poste</p>
                    <span>{download}</span>
                </div>
            </div>
        </div>
    )

}
