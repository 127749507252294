import { isBrowser, isMobile } from 'react-device-detect';
import { chat, bell } from '../../assets/icons/icons';
import { UserContext } from '../../contexts/UserContext';
import TopBarAction from '../topBarActionComponent/TopBarAction';
import './TopBarComponent.scss';

export default function TopBar() {

  const notifications = <TopBarAction icon={bell} label="Notifications" />
  const messagerie = <TopBarAction icon={chat} label="Messagerie" />
  const webContent = isBrowser && <div className="d-flex justify-content-around align-items-center text-center">{notifications} {messagerie} </div>

  return (
    <UserContext.Consumer>
      {
        user => {
          return (
            <div id="component-topBar" className={isBrowser ? 'browser' : isMobile ? 'mobile' : ''}>
              <img id="logo" className={isBrowser ? 'browserLogo' : isMobile ? 'mobileLogo' : ''} src="/assets/images/meedic.png" alt="logo" />
              {user.isLogged ? webContent : null}
            </div>
          )

        }
      }
    </UserContext.Consumer>
  )
}
