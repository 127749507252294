import React from 'react';
import './ListeCandidatComponent.scss';
import { download, excel, powerpoint, word } from '../../assets/icons/icons';


export default function ListeCandidatComponent() {
    return (
        <div id="component-listeCandidat" className="row">
            <div className="col-8 p-0">
                <ul>
                    <li><h4>Valérie, 41 ans</h4></li>
                    <li><p>Bondy, 93</p></li>
                    <li><h3>Assistant médico-administrative</h3></li>
                    <li><b>Spécialité</b> : Laboratoire, cardiologie, chirurgie</li>
                    <li><b>Permis</b> : B, véhiculée</li>
                    <li><b>Langues</b> : Anglais, Espagnol</li>

                    <li className="cercle-wrapper">
                        <ul className="d-flex">
                            <li>{word}</li>
                            <li><div className="cercle border"></div></li>
                            <li><div className="cercle border"></div></li>
                            <li><div className="cercle border"></div></li>
                        </ul>
                        <ul className="d-flex">
                            <li>{excel}</li>
                            <li><div className="cercle border"></div></li>
                            <li><div className="cercle border"></div></li>
                            <li><div className="cercle border"></div></li>
                        </ul>
                        <ul className="d-flex">
                            <li>{powerpoint}</li>
                            <li><div className="cercle border"></div></li>
                        </ul>
                    </li>

                    <li><b>Dernier poste occupé</b> : AMA service cardio la pitié</li>
                    <li><b>Disponibilié</b> : Fin novembre 2021</li>
                </ul>
            </div >


            <div className="col-3 p-0 d-flex flex-column justify-content-center align-items-center">
                <div>
                    <img src="/assets/images/empty.png" alt="photo" />
                </div>
                <div className="text-center">
                    <a href="##">Présentation vidéo</a>
                    <p>Télécharger le cv</p>
                    <span>{download}</span>
                </div>
            </div>
        </div >
    )

}
